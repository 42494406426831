<template>
  <v-main app>
    <v-container class="pt-0">
      <v-expand-transition mode="out-in">
        <router-view />
      </v-expand-transition>
    </v-container>
    <div class="flex-grow-1" />
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getThemeMode'])
  }
}
</script>

<style lang="scss">
.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 100% !important;
}

.v-input .v-label {
  font-size: 0.8em;
}

.v-text-field input {
  font-size: 0.9em;
}

.v-select {
  font-size: 0.9em;
}
</style>
